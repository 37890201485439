<template>
  <v-menu
    v-model="visibleMenu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-icon>
          <v-icon :color="menuItemColorClass">{{ iconConfigure }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ menuTitle }}</v-list-item-title>
      </v-list-item>
    </template>

    <user-settings @close="close">{{ dialogTitle }}</user-settings>
  </v-menu>
</template>

<script>
// Services
import { iconConfigure } from "@/design/icon/iconConst";

// model
import { commandNames } from "@/model/common/commands/commandModel";
import { eventNames } from "@/model/common/events/eventConst";

// Mixins
import { menuItemSettingsMixin } from "@/mixins/shared/base/settings/menuItemSettingsMixin";
import { userMixin } from "@/mixins/shared/user/userMixin";

export default {
  name: "PopoverMenuUserSettings",
  mixins: [menuItemSettingsMixin, userMixin],
  components: {
    UserSettings: () => import("@/components/shared/core/settings/UserSettings")
  },
  data() {
    return {
      visibleMenu: false,
      commandLabelClose: commandNames.close,
      iconConfigure: iconConfigure
    };
  },
  computed: {
    menuTitle() {
      return "Settings";
    },
    dialogTitle() {
      return `${this.actorName} ${this.menuTitle}`;
    }
  },
  methods: {
    close() {
      this.visibleMenu = false;
      this.$emit(eventNames.closed, false);
    }
  }
};
</script>
